import React from 'react';
import { Layout } from 'antd';

const Footer = () => {
  const { Footer } = Layout;

  return (
    <Footer style={{ textAlign: 'center' }}>Bridge for Billions ©2019 Created by B4B</Footer>
  );
};

export default Footer;
